<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col
                        md="4"
                        v-if="this.$store.getters['app/approvalAccess']('Receipt')"
                      >
                        <b-form-group>
                          <label>Book By</label>
                          <v-select
                            @input="getEmployee($event)"
                            v-model="bookby"
                            placeholder="None"
                            label="name"
                            :options="bookByOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="4"
                        v-if="
                          this.$store.getters['app/approvalAccess']('Receipt') &&
                          bookby &&
                          bookby.name !== 'Admin'
                        "
                      >
                        <b-form-group>
                          <label
                            >Select
                            {{
                              bookby && bookby.name == "Admin"
                                ? "Employee"
                                : bookby
                                ? bookby.name
                                : ""
                            }}</label
                          >
                          <v-select
                            v-model="employee"
                            label="fullname"
                            placeholder="None"
                            :options="employeeOptions"
                            @input="getClients"
                          >
                            <template
                              #option="{ name, profile_image, username, surname }"
                            >
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder">
                                {{ name }} {{ surname }}</span
                              ><span>({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="4"
                        v-if="
                          this.$store.getters['app/approvalAccess']('Receipt') &&
                          bookby &&
                          bookby.name !== 'Admin'
                        "
                      >
                        <b-form-group>
                          <label>Select Client</label>
                          <v-select
                            v-model="client"
                            label="fullname"
                            placeholder="None"
                            :options="$store.state.master.customersSales"
                            @input="getProjects"
                          >
                            <template #option="{ fullname, profile_image }">
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder"> {{ fullname }}</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group>
                          <label>Project Name</label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Project"
                          >
                            <v-select
                              v-model="projectname"
                              label="projectname"
                              placeholder="None"
                              :options="projectOptions"
                              @input="handleProject"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Plot </label>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Plot"
                          >
                            <v-select
                              v-model="plotno"
                              label="plotno"
                              placeholder="None"
                              :options="plotnoOptions"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <b-card-code id="plotstatement">
                <report-table :data="receipt_items" :columns="fields">
                  <template #download>
                    <div class="d-flex align-items-center justify-content-end">
                      <b-button
                        v-b-tooltip.hover.v-success
                        title="Print"
                        variant="gradient-success"
                        class="ml-2 btn-icon"
                        @click="printData"
                      >
                        <feather-icon icon="PrinterIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.v-success
                        title="Excel"
                        variant="gradient-success"
                        class="ml-2 btn-icon"
                        @click="clickExcel"
                      >
                        <!-- @click="excelData" -->
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </div>
                  </template>
                  <template #upperbody>
                    <div class="upper-body-data mb-5">
                      <table
                        border="1"
                        cellpadding="2"
                        class="table table-bordered"
                        style="border-collapse: collapse; min-width: 22cm; width: 100%"
                      >
                        <tbody class="text">
                          <tr>
                            <td colspan="5">
                              <div
                                style="
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                "
                              >
                                <div><b>Client : </b>{{ plot.customer }}</div>
                                <div
                                  v-if="sales.customer && sales.customer.profile_image"
                                >
                                  <b-avatar
                                    style="height: 50px; width: 50px"
                                    :src="getprofileImage(sales.customer.profile_image)"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <b>Project : </b
                              >{{ plot.project ? plot.project.projectname : "" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Plot No.</b></td>
                            <td>{{ plot.plotno }}</td>
                            <td><b>Plot Deal Amount</b></td>
                            <td>{{ sales.totalpayment }}</td>
                          </tr>
                          <tr>
                            <td><b>Plot area SBA (in yard)</b></td>
                            <td>{{ plot.plotareayd }}</td>
                            <td><b>Down Payment</b></td>
                            <td>{{ sales.firstpayment }}</td>
                          </tr>
                          <tr>
                            <td><b>Carpet area (in yard)</b></td>
                            <td>{{ plot.carpetarea_sqyds }}</td>
                            <td><b>Total EMI / Part payment</b></td>
                            <td>
                              {{
                                sales.booktype != "Full Payment" && sales.noofinstallment
                                  ? sales.noofinstallment
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Rate per sq. yard</b></td>
                            <td>{{ sales.plotrate }}</td>
                            <td><b>Amount of EMI / Part payment</b></td>
                            <td>
                              {{
                                sales.booktype != "Full Payment" &&
                                sales.noofinstallment &&
                                sales.installmentamt
                                  ? sales.installmentamt / sales.noofinstallment
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Plot Value</b></td>
                            <td>{{ sales.plotvalue }}</td>
                            <td><b>Discount</b></td>
                            <td>{{ sales.discount }}</td>
                          </tr>
                          <tr>
                            <td><b>Payment Schedule</b></td>
                            <td>
                              {{
                                sales.paymenttermday
                                  ? sales.paymenttermday +
                                    (sales.paymenttermday &&
                                    sales.paymenttermday.includes("day")
                                      ? ""
                                      : " days")
                                  : ""
                              }}
                            </td>
                            <td><b>Discount Remarks</b></td>
                            <td>
                              <div style="display: flex; justify-content: space-between">
                                <span>
                                  {{ sales.disremarks }}
                                </span>
                                <div v-if="sales.discount_attachment">
                                  <attachment :data="sales.discount_attachment" />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Booking Date</b></td>
                            <td>
                              {{
                                sales.bookingdate
                                  ? moment(sales.bookingdate).format("DD/MM/yyyy")
                                  : ""
                              }}
                            </td>
                            <td><b>Sale by Name & Team</b></td>
                            <td>
                              {{ teamname }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Last payment date</b></td>
                            <td>
                              {{
                                lastReceiptDate
                                  ? moment(lastReceiptDate).format("DD/MM/yyyy")
                                  : ""
                              }}
                            </td>
                            <td><b>Plot Status</b></td>
                            <td>{{ plot.status }}</td>
                          </tr>
                        </tbody>
                        <tbody></tbody>
                      </table>
                    </div>
                  </template>
                  <template #action="{ row }">
                    <div class="nonPrintable">
                      <b-badge
                        :id="'download' + row.id"
                        @click="handelRecieptPDF(row)"
                        variant="success"
                        class="mr-50 cursor-pointer"
                      >
                        <b-spinner v-if="row.downloading" small variant="light" />

                        <feather-icon v-else icon="DownloadIcon" />
                      </b-badge>

                      <b-tooltip
                        :id="'download' + row.id"
                        :target="'download' + row.id"
                        triggers="hover"
                      >
                        Download
                      </b-tooltip>
                    </div>
                  </template>
                </report-table>
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Receipt"
      :html2canvas="{}"
      :jsPDF="{
        format: 'a5',
      }"
      :pdf-quality="3"
      :manual-pagination="false"
      pdf-format="a5"
      :pdf-margin="0"
      pdf-orientation="landscape"
      pdf-content-width="1800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <print-comp :receiptData="receiptData" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import printComp from "@/views/print/printReceipt.vue";

import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import attachment from "@/components/Attechment.vue";
import {
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  VBTooltip,
  BAvatar,
  BBadge,
  BTooltip,
} from "bootstrap-vue";
import VueHtml2pdf from "vue-html2pdf";

import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    printComp,
    BBadge,
    BTooltip,
    attachment,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BAvatar,
    GoodTableColumnSearch,
    VueHtml2pdf,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      moment,
      result: "",
      teamname: "",
      data: "",
      projectname: "",
      projectOptions: [],
      bookby: "",
      employee: "",
      client: "",
      employeeOptions: [],
      plotno: "",
      plotnoOptions: [],
      sales: {},
      plot: {},
      receipt_items: [],
      status: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      receiptData: {},
      fields: [
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "EMI No.",
          field: "emino",
        },
        {
          label: "Payment",
          field: "amount",
        },

        {
          label: "Total Received",
          field: "paid",
        },
        {
          label: "Balance Amount",
          field: "balance",
        },
        {
          label: "Remarks",
          field: "remarks",
        },
        {
          label: "Ref No",
          field: "utrno",
        },
        {
          label: "Rece Bank",
          field: "bankname",
        },
        {
          label: "",
          field: "action",
          nonPrintable: true,
        },
      ],
      bookByOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Employee", id: "employee" },
        { name: "Admin", id: "admin" },
      ],
      lastReceiptDate: "",
    };
  },
  watch: {
    "$store.state.app.user_data": {
      immediate: true,
      handler(newValue, oldValue) {
        if (!this.$store.getters["app/approvalAccess"]("Receipt")) {
          this.employee = newValue;
          this.getProjects();
        }
      },
    },
  },
  mounted() {
    if (!this.$store.getters["app/approvalAccess"]("Receipt")) {
      this.employee = this.$store.state.app.user_data;
      this.getProjects();
    }
    this.$store.dispatch("master/getCustomerBySales");
  },
  methods: {
    handleProject(e) {
      if (e && e.plot_data) {
        this.plotnoOptions = e.plot_data;
        return;
      }
      this.plotnoOptions = [];
    },
    async getEmployee(e) {
      this.employeeOptions = [];
      this.employee = "";
      this.plot = {};
      this.plotno = "";
      this.project = "";
      this.projectOptions = [];
      this.sales = {};
      if (e && e.id != "admin") {
        let obj = {
          channelpartner: "getChannelpartner",
          franchise: "getFranchise",
          employee: "getEmployee",
        };
        await axios({
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/${obj[e.id]}`,
        }).then((response) => {
          this.employeeOptions = response.data.data;
        });
      } else if (e) {
        await axios({
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getalladmins`,
        }).then((response) => {
          this.employee =
            response.data && response.data.length > 0 ? response.data[0] : {};
          this.getClients(this.employee);
        });
      }
    },
    async handelRecieptPDF(row) {
      if (row.downloading) {
        return;
      }
      row.downloading = true;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getreceiptById/${row.receipt.id}`,
      };

      await axios(requestOptions)
        .then((json) => {
          this.receiptData = json.data.data[0];
          let year = moment(this.receiptData.created_date).year();
          let id = this.receiptData.id.toString().padStart(5, "0");
          this.receiptData.receiptNo = "NGR-" + year + "-" + id;

          if (this.receiptData && this.receiptData.receipt_items.length > 30) {
            this.$swal({
              title: "Denied",
              text: "Cannot generate receipt which contains more than 30 plot payments",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.receiptData.paymentDetails = "";
            if (
              parseFloat(this.receiptData?.receipt_items[0]?.remaining) +
                parseFloat(this.receiptData?.receipt_items[0]?.amount) ==
              parseFloat(this.receiptData.receipt_items[0].sales_data.totalpayment)
            ) {
              this.receiptData.paymenttype = this.receiptData?.receipt_items[0].sales_data.paymenttype;
            } else if (
              this.receiptData?.receipt_items[0].sales_data.bookingtype == "EMI"
            ) {
              this.receiptData.paymenttype = "EMI";
            } else {
              this.receiptData.paymenttype = "Part Payment";
            }
            this.$refs.html2Pdf.generatePdf();
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          row.downloading = false;
          this.$forceUpdate();
        });
    },
    clickExcel() {
      var aoa = [
        ["Plient :", this.sales.customer ? this.sales.customer.fullname : ""],
        ["Project :", this.plot.project ? this.plot.project.projectname : ""],
        ["Plot No. :", this.plot.plotno, "Plot Value :", this.sales.totalamount],
        [
          "Plot area SBA (in yard) :",
          this.plot.plotareayd,
          "Down Payment :",
          this.sales.firstpayment,
        ],
        [
          "Carpet area (in yard) :",
          this.plot.carpetarea_sqyds,
          "Total EMI / Part payment :",
          this.sales.noofinstallment ? this.sales.noofinstallment : "",
        ],
        [
          "Rate per sq. yard :",
          this.sales.plotrate,
          "Amount of EMI / Part payment :",
          this.sales.noofinstallment && this.sales.installmentamt
            ? this.sales.installmentamt / this.sales.noofinstallment
            : "",
        ],
        [],
        [],
        [],
        [
          "Sr. No",
          "Type",
          "Date",
          "EMI No.",
          "Payment",
          "Total Received",
          "Balance Amount",
          "Remarks",
          "Ref No",
          "Rece Bank",
        ],
      ];
      this.receipt_items.map((item, index) => {
        aoa.push([
          index + 1,
          item.type,
          item.date,
          item.emino,
          item.amount,
          item.paid,
          item.balance,
          item.remarks,
          item.utrno,
          item.bankname,
        ]);
      });

      var ws = XLSX.utils.aoa_to_sheet(aoa);
      var html_string = XLSX.utils.sheet_to_html(ws, {
        id: "data-table",
        editable: true,
      });
      document.getElementById("container").innerHTML = html_string;
      function doit(type, fn) {
        var elt = document.getElementById("data-table");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        XLSX.writeFile(
          wb,
          fn ||
            "Plot statement -" +
              this.plot.plotno +
              " (" +
              this.plot.project.projectname +
              ")." +
              (type || "xlsx")
        );
      }
      doit(
        "xlsx",
        "Plot statement - " +
          this.plot.plotno +
          " (" +
          this.plot.project.projectname +
          ").xlsx"
      );
    },
    async searchData() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          const data = {
            plot_id: this.plotno && this.plotno.id,
          };
          await axios({
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
            url: `${this.baseApi}/plotstatement`,
          }).then((response) => {
            this.sales = response.data.sales;
            this.plot = response.data;
            let paid = 0;
            let emi = 0;
            if (
              this.sales &&
              response.data.receipt_items &&
              response.data.receipt_items.length > 0
            ) {
              let name = this.sales.customer ? this.sales.customer.fullname : "";
              let city = this.sales.customer ? this.sales.customer.curcity : "";
              let email = this.sales.customer ? this.sales.customer.email : "";
              let mobile = this.sales.customer ? this.sales.customer.mobile : "";
              let arr = [name, city, email, mobile].filter((item) => item);

              this.plot.customer = arr.join(", ");
              let team = this.sales.team ? this.sales.team.fullname : "";
              let master_parent = this.plot.master_parent
                ? this.plot.master_parent.fullname
                : "";
              this.teamname = team + " - " + (master_parent != team ? master_parent : "");
              let balance = this.sales.totalpayment;
              this.receipt_items = response.data.receipt_items.map((item) => {
                this.lastReceiptDate = item.receiptdate;
                item.type = !paid ? "Booking / DP" : "EMI / Part Payment";
                item.emino = paid ? ++emi : "";
                item.date = item.receiptdate
                  ? moment(item.receiptdate).format("DD/MM/yyyy")
                  : "";
                item.balance = this.roundOfDecimal(
                  parseFloat(balance) - parseFloat(item.amount),
                  2
                );
                item.paid = this.roundOfDecimal(
                  parseFloat(paid) + parseFloat(item.amount),
                  2
                );
                paid += this.roundOfDecimal(parseFloat(item.amount), 2);
                balance -= this.roundOfDecimal(parseFloat(item.amount), 2);

                item.bankname = item.receipt?.ledgername?.name;

                return item;
              });
            }

            // this.data = response.data.data;
          });
        }
      });
    },
    printData() {
      const options = {
        styles: ["/css/printTable.css"],
      };
      this.$htmlToPaper("all-data", options);

      // newWin.close();
    },
    async getClients() {
      this.projectOptions = [];
      this.project = "";
      this.plot = {};
      this.plotno = "";
      this.sales = {};
      if (this.employee) {
        this.$store.dispatch("master/getCustomerBySales", this.employee.id);
      }
      this.getProjects();
    },
    async getProjects() {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProjectPlots`,
        data: {
          user_id: this.employee?.id,
          client_id: this.client?.id,
        },
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.upper-body-data {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.upper-body-data td {
  color: black;
}
</style>
